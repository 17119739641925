import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { caseStudies } from "."
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import MandalaBanner from "../../components/CaseStudy/MandalaBanner"
import {
    MandalaImageFive,
    MandalaImageFour,
    MandalaImageOne,
    MandalaImageThree,
    MandalaImageTwo
} from "../../components/CaseStudy/MandalaImage"
import Overview from "../../components/CaseStudy/Overview"
import ContentImage from "../../components/ContentImage/ContentImage"
import Newsletter from "../../components/Form/Newsletter"
import Hello from "../../components/Hello/Hello"
import HeroTwo from "../../components/Hero/HeroTwo"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import TitleContent from "../../components/TitleContent/TitleContent"
import "./case-studies.scss"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const techs = [
    { src: "/case-study/next.svg" },
    { src: "/case-study/sanity.png" },
    { src: "/case-study/netlify.svg" },
    { src: "/case-study/react.svg" },
    { src: "/case-study/gsap.png" }
]

const sliderImages = [
    {
        src: "/case-study/mandala-slider-1.webp",
        text: "We solve economic, policy and regulatory problems",
        component: MandalaImageOne
    },
    {
        src: "/case-study/mandala-slider-2.webp",
        text: "We use novel data and strategy for the changing world",
        component: MandalaImageTwo
    },
    {
        src: "/case-study/mandala-slider-3.webp",
        text: "AI Human Capital Investment Index",
        component: MandalaImageThree
    },
    {
        src: "/case-study/mandala-slider-4.webp",
        text: "Proven strategy consulting methods",
        component: MandalaImageFour
    },
    {
        src: "/case-study/mandala-slider-5.webp",
        text: "Expert advice in regulatory and policy strategy",
        component: MandalaImageFive
    }
]

const overview = {
    overview:
        "Mandala Partners, an economic, policy, and regulatory consultancy, needed a dynamic, user-friendly, and professional website to strengthen their brand and enhance their communication with clients. They sought to build a unique and customiststsed website that would stand out in their field.",
    services: ["Development", "UI/UX", "SEO"],
    linkUrl: "https://mandalapartners.com/"
}

const lhScores = [
    {
        title: "Performance",
        score: 100
    },
    {
        title: "Accessibility",
        score: 91
    },
    {
        title: "Best Practices",
        score: 92
    },
    {
        title: "SEO",
        score: 100
    }
]

const tlDefaults = {
    ease: "slow.inOut",
    duration: 1.25
}

const MandalaDetail = ({ location }) => {
    const $sliderSection = useRef(null)
    const $pageWrapper = useRef(null)
    const $slider = useRef(null)
    const $progress = useRef(null)
    const $contentImageWrapper = useRef(null)
    const $contentImagePin = useRef(null)

    const currentSlide = useRef(undefined)
    const next = useRef(0)
    const alreadyEntered = useRef(false)

    const heroData = {
        preheader: "Mandala Partners",
        title: "Economics, policy and strategy for the changing world"
    }
    const context = {
        pageName: "SD | Case studies - Mandala",
        pageUri: location.href
    }

    useEffect(() => {
        const ctx = gsap.context(() => {
            const outers = gsap.utils.toArray(".slider-outer")
            const inners = gsap.utils.toArray(".slider-inner")
            const images = gsap.utils.toArray(".slider-image")

            gsap.set(outers, { yPercent: 100 })
            gsap.set(inners, { yPercent: -100 })
            gsap.set(images, { scale: 1.3, yPercent: 15 })
            gsap.set($progress.current, { scaleX: 0 })

            ScrollTrigger.create({
                trigger: $sliderSection.current,
                start: "top top",
                // markers: true,
                onEnter: () => {
                    if (!alreadyEntered.current) {
                        slideIn()
                    }

                    alreadyEntered.current = true
                }
            })
        })

        return () => {
            ctx.revert()
        }
    }, [])

    useEffect(() => {
        const mm = gsap.matchMedia()

        mm.add("(min-width: 1024px)", () => {
            const contentImages =
                $contentImageWrapper.current.querySelectorAll(".content-image")

            contentImages[0].classList.add("is-active")

            ScrollTrigger.create({
                trigger: $contentImageWrapper.current,
                // pin: $contentImagePin.current,
                // pinSpacer: false,
                start: "top top",
                // end: `+=${contentImages.length * 100}% bottom`,
                // markers: true,
                onLeave: () => {
                    contentImages[0].classList.remove("is-active")
                    contentImages[contentImages.length - 1].classList.add(
                        "is-active"
                    )
                },
                onUpdate: self => {
                    const progress = Number(self.progress.toFixed(3))
                    const total = contentImages.length + 1

                    contentImages.forEach((_, i) => {
                        const oldIndex = i
                        const newIndex = oldIndex + 1

                        if (
                            progress > oldIndex / total &&
                            progress < newIndex / total
                        ) {
                            contentImages[oldIndex].classList.add("is-active")

                            if (self.direction === 1 && oldIndex > 0) {
                                contentImages[oldIndex - 1].classList.remove(
                                    "is-active"
                                )
                            } else if (
                                self.direction === -1 &&
                                newIndex < contentImages.length
                            ) {
                                contentImages[newIndex].classList.remove(
                                    "is-active"
                                )
                            }
                        }
                    })
                }
            })
        })

        return () => {
            mm.revert()
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSlide.current !== undefined) {
            }
        }, 4000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    // Slides a section in on scroll down
    function slideIn() {
        const outers = gsap.utils.toArray(".slider-outer")
        const inners = gsap.utils.toArray(".slider-inner")
        const images = gsap.utils.toArray(".slider-image")
        const texts = gsap.utils.toArray(".slider-text")

        // The first time this function runs, currentSlide is undefined
        const tl = gsap.timeline({
            paused: true,
            defaults: tlDefaults,
            onComplete: () => {
                currentSlide.current = next.current
                next.current = currentSlide.current + 1
                if (next.current > sliderImages.length - 1) {
                    currentSlide.current = sliderImages.length - 1
                    next.current = 0
                }

                const timeout = setTimeout(() => {
                    slideIn()
                }, 2000)
            }
        })

        tl.to(outers[next.current], { yPercent: 0 }, 0)
            .to(inners[next.current], { yPercent: 0 }, 0)
            .to(images[next?.current], { yPercent: 0, scale: 1 }, 0)
            .to(
                $progress.current,
                { scaleX: (next.current + 1) / sliderImages.length },
                0
            )
            .fromTo(
                texts[next?.current],
                { y: 40, opacity: 0 },
                { y: 0, opacity: 1 },
                0
            )

        if (currentSlide?.current !== undefined) {
            tl.to(outers[currentSlide.current], { yPercent: -100 }, 0)
                .to(inners[currentSlide.current], { yPercent: 100 }, 0)
                .to(
                    images[currentSlide.current],
                    { scale: 1.2, yPercent: -15 },
                    0
                )
                .to(
                    texts[currentSlide?.current],
                    { y: -40, opacity: 0, duration: 0.8 },
                    0
                )

            tl.add(
                gsap
                    .timeline()
                    .set(outers[currentSlide?.current], {
                        yPercent: 100
                    })
                    .set(inners[currentSlide?.current], {
                        yPercent: -100
                    })
                    .set(images[currentSlide?.current], {
                        yPercent: 15,
                        scale: 1.3
                    })
            )
        }

        tl.play(0)
    }

    return (
        <div ref={$pageWrapper}>
            <Layout context={context}>
                <HeroTwo props={heroData} />
                {/* Overview */}
                <Overview {...overview} />
                {/* banner */}
                <MandalaBanner className={"mt-20 lg:mt-24"}>
                    <StaticImage
                        src={"../../../static/case-study/mandala-mockup.png"}
                        alt="mandala mockup"
                        objectFit="cover"
                        className="h-full w-full"
                    />
                </MandalaBanner>

                <TitleContent
                    title="A JAMstack delivery of the best tools available"
                    className="py-14 lg:py-24"
                >
                    <p className="mb-4">
                        Saigon Digital teamed up with Mandala Partners to
                        deliver a tailored solution. We adopted one of our
                        favourite tech stacks, including NextJS alongside
                        Tailwind UI for the frontend and Sanity CMS for the
                        backend.
                    </p>
                    <p>
                        Our focus was on creating a responsive and user-friendly
                        UI and developing a flexible content editing system. To
                        bring the website to life, our team added in the GSAP
                        Animation Framework to add in performant and sleek
                        animations across that site that resonated with the
                        professional look and feel of Mandala brand.
                    </p>

                    <h3 className="mt-6 text-lg font-semibold">
                        Technologies used:
                    </h3>
                    <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                        {techs.map(({ src }, index) => {
                            return (
                                <div class="group grid aspect-[160/78] place-items-center border py-2 px-6 transition-all duration-300 hover:shadow-light_shadow">
                                    <img
                                        src={src}
                                        className="h-full w-full max-w-full object-contain grayscale transition-all duration-300 group-hover:grayscale-0"
                                        alt="logos"
                                    />
                                </div>
                            )
                        })}
                    </div>
                </TitleContent>
                {/* developing */}
                <Hello
                    link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                    title="With Google Lighthouse Scores to boast..."
                    lhScores={lhScores}
                >
                    <p className="mb-4">
                        Lighthouse is an open-source, automated tool for
                        improving the performance, quality, and correctness of
                        your web apps.
                    </p>
                    <p>
                        This is the real point of Google, you can check it here:{" "}
                        <a
                            href="/mandala-lighthouse-report.html"
                            target="_blank"
                            download
                            className="font-semibold text-primary-color underline"
                        >
                            Google Lighthouse Report
                        </a>
                    </p>
                </Hello>

                <div className="h-screen" ref={$sliderSection}>
                    <div className="flex min-h-screen flex-col pt-14 lg:pt-24">
                        <TitleContent
                            title="The Challenge"
                            className={"mb-10 lg:mb-16"}
                        >
                            <p className="mb-4">
                                With no existing website, the project required a
                                strategic approach right from the start.
                            </p>
                            <p>
                                Saigon Digital was entrusted with the
                                responsibility to design and develop the entire
                                website from scratch, keeping in mind Mandalas’s
                                unique business requirements, industry
                                standards, and the need for a high-performing
                                and visually compelling digital platform.
                            </p>
                        </TitleContent>

                        <div className="relative flex-1 overflow-hidden">
                            <div className="absolute inset-0">
                                <div
                                    className="mandala-slider relative h-full w-full overflow-hidden will-change-transform"
                                    ref={$slider}
                                >
                                    {sliderImages.map((image, index) => {
                                        const ImageComponent = image.component
                                        return (
                                            <div
                                                className="slider-wrapper absolute inset-0"
                                                style={{
                                                    zIndex: 10 - index
                                                }}
                                            >
                                                <div className="slider-outer relative h-full  w-full overflow-hidden will-change-transform">
                                                    <div className="slider-inner absolute inset-0 will-change-transform">
                                                        <div
                                                            className={`slider-image relative h-full w-full bg-cover bg-center will-change-transform`}
                                                        >
                                                            {/* <img
                                                                src={image.src}
                                                                alt=""
                                                                className="h-full w-full object-cover"
                                                                loading="lazy"
                                                            /> */}
                                                            <ImageComponent />
                                                            <div className="absolute inset-0 bg-black/40"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="absolute inset-0 z-10">
                                                    <div className="relative top-1/3 w-full">
                                                        <div className="container">
                                                            <StaticImage
                                                                src="../../../static/case-study/mandala-logo.svg"
                                                                className="mb-6"
                                                                objectFit="contain"
                                                            />
                                                            <h2 className="slider-text max-w-md text-2xl font-semibold tracking-wide text-white lg:max-w-lg lg:text-[32px] lg:leading-[45px]">
                                                                {image.text}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div
                                className="absolute bottom-0 left-0 h-1 w-full origin-left bg-white"
                                ref={$progress}
                            ></div>
                        </div>
                    </div>
                </div>

                {/* approach */}
                <TitleContent
                    title="The Approach"
                    className="mt-14 mb-10 lg:mt-24 lg:mb-16"
                >
                    <p className="">
                        Saigon Digital teamed up with Mandala Partners to
                        deliver a tailored solution. We adopted a new tech
                        stack, including NextJS for the frontend and Sanity CMS
                        for the backend. Our focus was on creating a responsive
                        and user-friendly UI and developing a flexible content
                        editing system.
                    </p>
                </TitleContent>
                {/* Solution */}
                <TitleContent className="mb-10 lg:mb-16" title="The Solutions">
                    <p className="mb-4">
                        To overcome this challenge, Mandala sought to create a
                        brand-new website that would serve as their digital
                        storefront and effectively communicate their value
                        proposition to the target audience.
                    </p>
                    <p className="">
                        They wanted a website that exuded professionalism,
                        reflected their brand identity, and provided a seamless
                        user experience.  The new website would help position
                        Mandala as a reputable and reliable financial advisory
                        firm, capable of delivering exceptional services to
                        businesses worldwide.
                    </p>
                </TitleContent>
                {/* banner 2 */}
                <div className="container mb-10 grid gap-y-10 gap-x-[30px] md:grid-cols-2 lg:mb-16 lg:gap-x-24">
                    <div className="aspect-[475/356] w-full overflow-hidden md:ml-5">
                        <div className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105">
                            <StaticImage
                                src="../../../static/case-study/mandala-banner-1.png"
                                className="h-full w-full"
                                objectFit="cover"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="aspect-[475/356] w-full overflow-hidden md:mr-5">
                        <div className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105">
                            <StaticImage
                                src="../../../static/case-study/mandala-banner-2.png"
                                className="h-full w-full"
                                objectFit="cover"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <Newsletter context={context} />

                <TitleContent className="mb-10 lg:mb-16" title="Outcomes">
                    <p>
                        The collaboration between Saigon Digital and Mandala
                        resulted in bringing the new brand and UI to life in the
                        digital space. The new website boasts lightning-fast
                        load times, captivating animations, and seamless
                        interactions. Content management is now effortless,
                        empowering the Mandala team to update and publish
                        content independently using Sanity’s Content Studio.
                    </p>
                </TitleContent>

                <div ref={$contentImageWrapper}>
                    <div
                        className="relative lg:sticky lg:top-[20vh] lg:h-screen"
                        ref={$contentImagePin}
                    >
                        <ContentImage
                            className="lg:opacity-100"
                            img={"/case-study/mandala-content-image-1.png"}
                        >
                            <h2 className="mb-4 text-2xl font-semibold">
                                A new look for Mandala
                            </h2>

                            <p className="mb-4">
                                From brief to reality - a professional and
                                industry-leading look and feel for the new
                                website.
                            </p>
                            <p>
                                The end result was a visually stunning,
                                user-friendly, and high-performing website that
                                reflected the client's brand values, captured
                                their target audience's attention, and
                                positioned them as leaders in their industry.
                            </p>
                        </ContentImage>
                        <ContentImage
                            img={"/case-study/mandala-content-image-2.png"}
                        >
                            <h2 className="mb-4 text-2xl font-semibold">
                                Next level Content Management
                            </h2>

                            <p>
                                Sanity.io has empowered Mandalas team with a
                                user-friendly interface, flexible content
                                management, collaboration capabilities, and the
                                ability to deliver consistent and structured
                                data which is inline with their brand
                                guidelines.
                            </p>
                        </ContentImage>
                        <ContentImage
                            img={"/case-study/mandala-content-image-3.png"}
                        >
                            <h2 className="mb-4 text-2xl font-semibold">
                                Seamless Typeform Integration
                            </h2>

                            <p className="mb-4">
                                Typeform's interactive forms allowed the website
                                to capture leads more effectively.
                            </p>
                            <p>
                                The dynamic and engaging nature of Typeform's
                                forms encouraged visitors to provide their
                                information willingly, leading to a higher
                                conversion rate for lead generation campaigns.
                            </p>
                        </ContentImage>
                        <ContentImage
                            img={"/case-study/mandala-content-image-4.png"}
                        >
                            <h2 className="mb-4 text-2xl font-semibold">
                                Interactive Data Visualisation
                            </h2>

                            <p>
                                The Mandala website includes interactive charts
                                using Flourish enabling data-driven storytelling
                                and effective communication for their reports
                                which they publish on the website.
                            </p>
                        </ContentImage>
                    </div>
                    <div className="lg:h-[400vh]"></div>
                </div>

                <TitleContent
                    className="mb-10 lg:mb-16 lg:-mt-[30vh]"
                    title="The Results"
                >
                    <p className="mb-4">
                        The collaboration resulted in a dynamic and
                        user-friendly website that aligns well with the client's
                        goals. The new site offers a unique and professional
                        interface that allows for easy content updates and reuse
                        of developed components.
                    </p>
                    <p>
                        Mandala and their team were very satisfied with the
                        final product, which helped them brand themselves and
                        facilitate more conversations with their clients.
                    </p>
                </TitleContent>
                <MandalaBanner className={""}>
                    <StaticImage
                        src={"../../../static/case-study/mandala-mockup-2.png"}
                        alt="mandala mockup"
                        objectFit="cover"
                        className="h-full w-full"
                    />
                </MandalaBanner>

                <StaticTestimonial
                    className={"-mt-[2px]"}
                    title={"Thoughts from the Mandala Team"}
                    content={`We worked with Saigon Digital over many months to design and deploy our new website. Saigon Digital designed us a website that is unique, customised, and professional - that we have received positive feedback on all round. Working with Saigon Digital was easy and they are highly responsive and push to deliver great quality.`}
                    author={"Hayley Winchcombe"}
                    position="Associate at Mandala Partners"
                />
                <ShowCase data={caseStudies} />
            </Layout>
        </div>
    )
}

export default MandalaDetail

export const Head = () => (
    <SEO
        title={"Mandala Partners | Saigon Digital"}
        description={
            "Mandala's brand-new website is built with tech stack, frontend Next.js and backend Sanity CMS. Saigon Digital focus on creating a responsive and user-friendly UI for website development."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
    />
)
