import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const MandalaImageOne = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/mandala-slider-1.webp"}
                alt="mandala mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const MandalaImageTwo = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/mandala-slider-2.webp"}
                alt="mandala mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const MandalaImageThree = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/mandala-slider-3.webp"}
                alt="mandala mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const MandalaImageFour = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/mandala-slider-4.webp"}
                alt="mandala mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const MandalaImageFive = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/mandala-slider-5.webp"}
                alt="mandala mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

export {
    MandalaImageOne,
    MandalaImageTwo,
    MandalaImageThree,
    MandalaImageFour,
    MandalaImageFive
}
